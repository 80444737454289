// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllDataSitesClient = createAsyncThunk('appUsers/getAllDataSitesClient', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/client/sites/all', { params: { id} })

  return response.data

})

export const getDataSitesClients = createAsyncThunk('appUsers/getDataSitesClients', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/client/sites/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})
//add site 


export const getClient = createAsyncThunk('appUsers/getClient', async id => {

  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/clients/client', { params: { id} })
  console.log(response.data)

  return response.data

})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {

  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user

})

export const addSite = createAsyncThunk('appUsers/addSite', async (site, { dispatch, getState }) => {
  site.client_id = getState().sitesClient.params.id
  console.log(getState().sitesClient)

  await axios.post('https://apiv3dev.opentlv.com/api/v3/client/addsite', site)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataSitesClients(getState().sitesClient.params))

  return site
})

export const getPlayerTypes = createAsyncThunk('appUsers/getPlayerTypes', async () => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/player/types')

  return response.data
})

export const getMultiZones = createAsyncThunk('appUsers/getMultiZones', async () => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/player/multi/zones')

  return response.data
})

export const deletePlayer = createAsyncThunk('appUsers/deletePlayer', async (id, { dispatch, getState }) => {
  console.log("app")
  console.log(id)
//  site.id_site = getState().clients.selectedSite.id
  await axios.delete('https://apiv3dev.opentlv.com/api/v3/site/player/delete', {params: {id}})

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataSitesClients(getState().sitesClient.params))
  return site
})

export const deleteCamera = createAsyncThunk('appUsers/deleteCamera', async (id, { dispatch, getState }) => {

  await axios.delete('https://apiv3dev.opentlv.com/api/v3/site/camera/delete', {params: {id}})

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataSitesClients(getState().sitesClient.params))
  return site
})

export const addConfiguration = createAsyncThunk('appUsers/addConfiguration', async (config, { dispatch, getState }) => {

  config.client_id = getState().sitesClient.params.id
  console.log(config)

  await axios.post('https://apiv3dev.opentlv.com/api/v3/client/addconfig', config)

  //await dispatch(getAllDataSitesClient(getState().sitesClient.params.id))
  await dispatch(getDataSitesClients(getState().sitesClient.params))
  return site
})

export const deleteSite = createAsyncThunk('appUsers/deleteSite', async (id, { dispatch, getState }) => {
  console.log("getState()")
  console.log(id)

  await axios.delete('https://apiv3dev.opentlv.com/api/v3/client/site/delete', {params: {id}})
//  await dispatch(getData(getState().users.params))
    await dispatch(getDataSitesClients(getState().sitesClient.params))

  return id
})

export const appClientSitesSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    playerTypes: [],
    multiZones: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataSitesClient.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getDataSitesClients.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getPlayerTypes.fulfilled, (state, action) => {
        state.playerTypes = action.payload
      })
      .addCase(getMultiZones.fulfilled, (state, action) => {
        state.multiZones = action.payload
      })
    }
})

export default appClientSitesSlice.reducer
